export const reReportKindConstants = {
	CreateReportKind: {
		FAILURE : "CREATE_REPORTKIND_FAILURE",
		REQUEST : "CREATE_REPORTKIND_REQUEST",
		SUCCESS : "CREATE_REPORTKIND_SUCCESS"
	},
	DeleteReportKind: {
		FAILURE : "DELETE_REPORTKIND_FAILURE",
		REQUEST : "DELETE_REPORTKIND_REQUEST",
		SUCCESS : "DELETE_REPORTKIND_SUCCESS"
	},
	GetAllPaged: {
		CLEAR   : "CLEAR_REPORTKIND_ALL_PAGED",
		FAILURE : "GET_REPORTKIND_All_PAGED_FAILURE",
		REQUEST : "GET_REPORTKIND_All_PAGED_REQUEST",
		SUCCESS : "GET_REPORTKIND_All_PAGED_SUCCESS"
	},
	RestoreReportKind: {
		FAILURE : "RESTORE_REPORTKIND_FAILURE",
		REQUEST : "RESTORE_REPORTKIND_REQUEST",
		SUCCESS : "RESTORE_REPORTKIND_SUCCESS"
	},
	UpdateReportKind: {
		FAILURE : "UPDATE_REPORTKIND_FAILURE",
		REQUEST : "UPDATE_REPORTKIND_REQUEST",
		SUCCESS : "UPDATE_REPORTKIND_SUCCESS"
	}
}