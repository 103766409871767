export const reThemesOgulConstants = {
	GetReThemeOgul: {
		REQUEST : 'GET_RE_THEMESOGUL_REQUEST',
		SUCCESS : 'GET_RE_THEMESOGUL_SUCCESS',
		FAILURE : 'GET_RE_THEMESOGUL_FAILURE',
		CLEAR   : "GET_RE_THEMESOGUL_CLEAR"
	},
	CreateThemesOgul: {
		REQUEST : 'CREATE_THEMESOGUL_REQUEST',
		SUCCESS : 'CREATE_THEMESOGUL_SUCCESS',
		FAILURE : 'CREATE_THEMESOGUL_FAILURE'
	},
	DeleteThemesOgul: {
		REQUEST : 'DELETE_THEMESOGUL_REQUEST',
		SUCCESS : 'DELETE_THEMESOGUL_SUCCESS',
		FAILURE : 'DELETE_THEMESOGUL_FAILURE'
	},
	UpdateThemesOgul: {
		REQUEST : 'UPDATE_THEMESOGUL_REQUEST',
		SUCCESS : 'UPDATE_THEMESOGUL_SUCCESS',
		FAILURE : 'UPDATE_THEMESOGUL_FAILURE'
	},
	RestoreThemesOgul: {
		REQUEST : 'RESTORE_THEMESOGUL_REQUEST',
		SUCCESS : 'RESTORE_THEMESOGUL_SUCCESS',
		FAILURE : 'RESTORE_THEMESOGUL_FAILURE'
	}
}