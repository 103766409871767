export const reReasonTypesConstants = {
  SEND_REREASONTYPES_REQUEST: 'SEND_REREASONTYPES_REQUEST',
  SEND_REREASONTYPES_SUCCESS: 'SEND_REREASONTYPES_SUCCESS',
  SEND_REREASONTYPES_FAILURE: 'SEND_REREASONTYPES_FAILURE',
  getReasonTypes : {
    CLEAR: 'GET_REASON_TYPES_CLEAR',
  },
  createReasonType : {
    REQUEST: 'CREATE_REASON_TYPES_REQUEST',
    SUCCESS: 'CREATE_REASON_TYPES_SUCCESS',
    FAILURE: 'CREATE_REASON_TYPES_FAILURE',
  },
  deleteReasonType : {
    REQUEST: 'DELETE_REASON_TYPES_REQUEST',
    SUCCESS: 'DELETE_REASON_TYPES_SUCCESS',
    FAILURE: 'DELETE_REASON_TYPES_FAILURE',
  },
  updateReasonType : {
    REQUEST: 'UPDATE_REASON_TYPES_REQUEST',
    SUCCESS: 'UPDATE_REASON_TYPES_SUCCESS',
    FAILURE: 'UPDATE_REASON_TYPES_FAILURE',
  },
  restoreReasonType : {
    REQUEST: 'RESTORE_REASON_TYPES_REQUEST',
    SUCCESS: 'RESTORE_REASON_TYPES_SUCCESS',
    FAILURE: 'RESTORE_REASON_TYPES_FAILURE',
  }
}