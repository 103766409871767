export const reTaskTypesConstants = {
    getList: {
		FAILURE : 'GET_LIST_RE_TASK_TYPES_FAILURE',
		REQUEST : 'GET_LIST_RE_TASK_TYPES_REQUEST',
		SUCCESS : 'GET_LIST_RE_TASK_TYPES_SUCCESS',
	},
    getPaged: {
		FAILURE : 'GET_PAGED_RE_TASK_TYPES_FAILURE',
		REQUEST : 'GET_PAGED_RE_TASK_TYPES_REQUEST',
		SUCCESS : 'GET_PAGED_RE_TASK_TYPES_SUCCESS',
	},
    create: {
		FAILURE : 'CREATE_RE_TASK_TYPES_FAILURE',
		REQUEST : 'CREATE_RE_TASK_TYPES_REQUEST',
		SUCCESS : 'CREATE_RE_TASK_TYPES_SUCCESS'
	},
    delete: {
		FAILURE : 'DELETE_RE_TASK_TYPES_FAILURE',
		REQUEST : 'DELETE_RE_TASK_TYPES_REQUEST',
		SUCCESS : 'DELETE_RE_TASK_TYPES_SUCCESS'
	},
    update: {
		FAILURE : 'UPDATE_RE_TASK_TYPES_FAILURE',
		REQUEST : 'UPDATE_RE_TASK_TYPES_REQUEST',
		SUCCESS : 'UPDATE_RE_TASK_TYPES_SUCCESS'
	},
	restore: {
		FAILURE : 'RESTORE_RE_TASK_TYPES_FAILURE',
		REQUEST : 'RESTORE_RE_TASK_TYPES_REQUEST',
		SUCCESS : 'RESTORE_RE_TASK_TYPES_SUCCESS'
	},
	clear: 'RE_TASK_TYPES_CLEAR'
}