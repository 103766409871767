import React from "react"
import {
	notification
}from "antd"
import "antd/lib/notification/style/index.css"
import Scrollbars from "react-custom-scrollbars"

const infoNotice = (title, description = "", duration = null) => {
	notification["info"]({
		description: (
		<Scrollbars
			autoHeight
			autoHide
			autoHeightMax={ 700 }
		>
			<span>
				{ description }
			</span>
		</Scrollbars>
		),
		duration : duration,
		message : title,
		placement : "bottomLeft"
		})
}

const successNotice = (title, description = "", duration = null) => {
	return notification["success"]({
		description: (
		<Scrollbars
			autoHeight
			autoHide
			autoHeightMax={ 700 }
		>
			<span>
				{ description }
			</span>
		</Scrollbars>
		),
		duration : duration,
		message : title,
		placement : "bottomLeft"
		})
}

const warningNotice = (title, description = "", duration = null) => {
	notification["warning"]({
		description: (
		<Scrollbars
			autoHeight
			autoHide
			autoHeightMax={ 700 }
		>
			<span>
				{ description }
			</span>
		</Scrollbars>
		),
		duration : duration,
		message : title,
		placement : "bottomLeft"
		})
}

const errorNotice = (title, description = "", duration = null) => {
	notification["error"]({
		description: (
		<Scrollbars
			autoHeight
			autoHide
			autoHeightMax={ 700 }
		>
			<span>
				{ description }
			</span>
		</Scrollbars>
		),
		duration : duration,
		message : title,
		placement : "bottomLeft"
		})
}

export default {
	infoNotice,
	successNotice,
	warningNotice,
	errorNotice
}
