export const reRequestsStatusConstants = {
  SEND_REREQUESTSSTATUS_REQUEST: 'SEND_REREQUESTSSTATUS_REQUEST',
  SEND_REREQUESTSSTATUS_SUCCESS: 'SEND_REREQUESTSSTATUS_SUCCESS',
  SEND_REREQUESTSSTATUS_FAILURE: 'SEND_REREQUESTSSTATUS_FAILURE',
  SEND_REREQUESTSSTATUS_CLEAR: 'SEND_REREQUESTSSTATUS_CLEAR',
  createRequestStatus: {
    REQUEST: 'CREATE_REQUEST_STATUS_REQUEST',
    FAILURE: 'CREATE_REQUEST_STATUS_FAILURE',
    SUCCESS: 'CREATE_REQUEST_STATUS_SUCCESS',
  },
  deleteRequestStatus: {
    REQUEST: 'DELETE_REQUEST_STATUS_REQUEST',
    FAILURE: 'DELETE_REQUEST_STATUS_FAILURE',
    SUCCESS: 'DELETE_REQUEST_STATUS_SUCCESS',
  },
  restoreRequestStatus: {
    REQUEST: 'RESTORE_REQUEST_STATUS_REQUEST',
    FAILURE: 'RESTORE_REQUEST_STATUS_FAILURE',
    SUCCESS: 'RESTORE_REQUEST_STATUS_SUCCESS',
  },
  updateRequestStatus: {
    REQUEST: 'UPDATE_REQUEST_STATUS_REQUEST',
    FAILURE: 'UPDATE_REQUEST_STATUS_FAILURE',
    SUCCESS: 'UPDATE_REQUEST_STATUS_SUCCESS',
  }
}