export const reSocialGroupTypesConstants = {
  SEND_RESOCIALGROUPTYPES_REQUEST: 'SEND_RESOCIALGROUPTYPES_REQUEST',
  SEND_RESOCIALGROUPTYPES_SUCCESS: 'SEND_RESOCIALGROUPTYPES_SUCCESS',
  SEND_RESOCIALGROUPTYPES_FAILURE: 'SEND_RESOCIALGROUPTYPES_FAILURE',
  SEND_RESOCIALGROUPTYPES_CLEAR: 'SEND_RESOCIALGROUPTYPES_CLEAR',
  createSocialGroupType : {
    REQUEST: 'CREATE_SOCIAL_GROUP_TYPE_REQUEST',
    FAILURE: 'CREATE_SOCIAL_GROUP_TYPE_FAILURE',
    SUCCESS: 'CREATE_SOCIAL_GROUP_TYPE_SUCCESS',
  },
  deleteSocialGroupType : {
    REQUEST: 'DELETE_SOCIAL_GROUP_TYPE_REQUEST',
    FAILURE: 'DELETE_SOCIAL_GROUP_TYPE_FAILURE',
    SUCCESS: 'DELETE_SOCIAL_GROUP_TYPE_SUCCESS',
  },
  restoreSocialGroupType : {
    REQUEST: 'RESTORE_SOCIAL_GROUP_TYPE_REQUEST',
    FAILURE: 'RESTORE_SOCIAL_GROUP_TYPE_FAILURE',
    SUCCESS: 'RESTORE_SOCIAL_GROUP_TYPE_SUCCESS',
  },
  updateSocialGroupType : {
    REQUEST: 'UPDATE_SOCIAL_GROUP_TYPE_REQUEST',
    FAILURE: 'UPDATE,_SOCIAL_GROUP_TYPE_FAILURE',
    SUCCESS: 'UPDATE,_SOCIAL_GROUP_TYPE_SUCCESS',
  }
}