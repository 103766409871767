import {
	reReportConstants
}from "./constants"
import i18n from "../../localization"
import isRu from "../../../../helpers/isRu"

const initialState = {
	isSending     : false,
	reportChanged : null,
	reports       : []
}

export default function (state = initialState, action){
	switch (action.type){
		case reReportConstants.CreateReport.REQUEST :
		case reReportConstants.UpdateReport.REQUEST :
		case reReportConstants.DeleteReport.REQUEST :
		case reReportConstants.RestoreReport.REQUEST :
			return {
				...state,
				isSending     : true,
				reportChanged : null
			}
		case reReportConstants.CreateReport.SUCCESS :
			return {
				...state,
				isSending     : false,
				reportChanged : action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null
			}
		case reReportConstants.DeleteReport.SUCCESS :
			return {
				...state,
				isSending     : false,
				reportChanged : action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null
			}
		case reReportConstants.RestoreReport.SUCCESS :
			return {
				...state,
				isSending     : false,
				reportChanged : action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : null
			}
		case reReportConstants.UpdateReport.SUCCESS :
			return {
				...state,
				isSending     : false,
				reportChanged : action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null
			}
		case reReportConstants.CreateReport.FAILURE :
		case reReportConstants.UpdateReport.FAILURE :
		case reReportConstants.DeleteReport.FAILURE :
		case reReportConstants.RestoreReport.FAILURE :
			return {
				...state,
				error         : action.payload,
				isSending     : false,
				reportChanged : null
			}
		case reReportConstants.GetAllPaged.REQUEST :
		case reReportConstants.GetAllReports.REQUEST :
		case reReportConstants.GetAllReportsGroup.REQUEST :
			return {
				...state,
				isSending: true
			}
		case reReportConstants.GetAllPaged.SUCCESS :
		case reReportConstants.GetAllReports.SUCCESS :
		case reReportConstants.GetAllReportsGroup.SUCCESS :
			return {
				...state,
				isSending : false,
				reports   : action.payload.result
			}
		case reReportConstants.GetAllPaged.FAILURE :
		case reReportConstants.GetAllReports.FAILURE :
		case reReportConstants.GetAllReportsGroup.FAILURE :
			return {
				...state,
				error     : action.payload,
				isSending : false,
				reports   : []
			}
		case reReportConstants.GetAllPaged.CLEAR :
			return {
				...state,
				isSending     : false,
				reportChanged : null,
				reports       : []
			}
		default :
			return state
	}
}
