import {
	reReportKindConstants
}from "./constants"
import i18n from "../../localization"
import isRu from "../../../../helpers/isRu"

const initialState = {
	isSending         : false,
	reportKindChanged : null,
	reportKinds       : []
}

export default function (state = initialState, action){
	switch (action.type){
		case reReportKindConstants.CreateReportKind.REQUEST :
		case reReportKindConstants.UpdateReportKind.REQUEST :
		case reReportKindConstants.DeleteReportKind.REQUEST :
		case reReportKindConstants.RestoreReportKind.REQUEST :
			return {
				...state,
				isSending         : true,
				reportKindChanged : null
			}
		case reReportKindConstants.CreateReportKind.SUCCESS :
			return {
				...state,
				isSending         : false,
				reportKindChanged : action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null
			}
		case reReportKindConstants.DeleteReportKind.SUCCESS :
			return {
				...state,
				isSending         : false,
				reportKindChanged : action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null
			}
		case reReportKindConstants.RestoreReportKind.SUCCESS :
			return {
				...state,
				isSending         : false,
				reportKindChanged : action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : null
			}
		case reReportKindConstants.UpdateReportKind.SUCCESS :
			return {
				...state,
				isSending         : false,
				reportKindChanged : action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null
			}
		case reReportKindConstants.CreateReportKind.FAILURE :
		case reReportKindConstants.UpdateReportKind.FAILURE :
		case reReportKindConstants.DeleteReportKind.FAILURE :
		case reReportKindConstants.RestoreReportKind.FAILURE :
			return {
				...state,
				error             : action.payload,
				isSending         : false,
				reportKindChanged : null
			}
		case reReportKindConstants.GetAllPaged.REQUEST :
			return {
				...state,
				isSending: true
			}
		case reReportKindConstants.GetAllPaged.SUCCESS :
			return {
				...state,
				isSending   : false,
				reportKinds : action.payload.result
			}
		case reReportKindConstants.GetAllPaged.FAILURE :
			return {
				...state,
				error       : action.payload,
				isSending   : false,
				reportKinds : []
			}
		case reReportKindConstants.GetAllPaged.CLEAR :
			return {
				...state,
				isSending         : false,
				reportKindChanged : null,
				reportKinds       : []
			}
		default :
			return state
	}
}
