import {
	reTaskTypesConstants
}from "./constants"

const initialState = {
	reTaskTypesPaged           : null,
	error                      : null,
	isSending                  : false,
	reTaskTypesList            : null,
    changeableStatusMessage    : null,
}

export default function (state = initialState, action){
	switch (action.type){
		case reTaskTypesConstants.getList.REQUEST:
			return {
				...state,
				isSending: true,
                reTaskTypesList : null
			}
		case reTaskTypesConstants.getList.SUCCESS:
			return {
				...state,
				isSending   : false,
			    reTaskTypesList : action.payload.result
			}
        case reTaskTypesConstants.getPaged.REQUEST:
            return {
                ...state,
                reTaskTypesPaged: null,
                isSending: true
            }
        case reTaskTypesConstants.getPaged.SUCCESS:
            return {
                ...state,
                isSending   : false,
                reTaskTypesPaged : action.payload.result
            }

        case reTaskTypesConstants.create.REQUEST:
        case reTaskTypesConstants.update.REQUEST:
        case reTaskTypesConstants.delete.REQUEST:
        case reTaskTypesConstants.restore.REQUEST:
            return {
                ...state,
                isSending : true,
                changeableStatusMessage : null,
            }
        case reTaskTypesConstants.create.SUCCESS:
        case reTaskTypesConstants.update.SUCCESS:
        case reTaskTypesConstants.delete.SUCCESS:
        case reTaskTypesConstants.restore.SUCCESS:
            return {
                ...state,
                isSending : false,
                changeableStatusMessage : action.payload.result
            }
        case reTaskTypesConstants.create.FAILURE:
        case reTaskTypesConstants.update.FAILURE:
        case reTaskTypesConstants.delete.FAILURE:
        case reTaskTypesConstants.restore.FAILURE:
        case reTaskTypesConstants.getList.FAILURE:
        case reTaskTypesConstants.getPaged.FAILURE:
            return {
                ...state,
                isSending : false,
                error : action.payload.result,
            }
        case reTaskTypesConstants.clear:
            return {
                ...state,
                reTaskTypesList            : null,
                changeableStatusMessage    : null,
                error                      : null,
                isSending                  : false,
                reTaskTypesPaged           : null,
            }
		default:
			return state
	}
}
