import i18n from '../localization'
import {
	reResponsibilityTypesConstants
}from './constants'
import isRu from '../../../helpers/isRu'

const initialState =
{
	currentItem               : {},
	isSending                 : false,
	list                      : [],
	responsibilityTypeChanged : null
}

export default function (state = initialState, action){
	switch (action.type){
		case reResponsibilityTypesConstants.SEND_RERESPONSIBILITYTYPES_REQUEST:
		case reResponsibilityTypesConstants.getList.REQUEST:
			return {
				...state,
				isSending: true
			}
		case reResponsibilityTypesConstants.SEND_RERESPONSIBILITYTYPES_SUCCESS:
			return {
				...state,
				isSending   : false,
				currentItem : action.payload
			}
		case reResponsibilityTypesConstants.getList.SUCCESS:
			return {
				...state,
				isSending : false,
				list      : action.payload.result
			}
		case reResponsibilityTypesConstants.SEND_RERESPONSIBILITYTYPES_FAILURE:
		case reResponsibilityTypesConstants.getList.FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}
		case reResponsibilityTypesConstants.createReResponsibilityType.REQUEST:
		case reResponsibilityTypesConstants.deleteReResponsibilityType.REQUEST:
		case reResponsibilityTypesConstants.restoreReResponsibilityType.REQUEST:
		case reResponsibilityTypesConstants.updateReResponsibilityType.REQUEST:
			return {
				...state,
				isSending                 : true,
				responsibilityTypeChanged : null
			}
		case reResponsibilityTypesConstants.createReResponsibilityType.FAILURE:
		case reResponsibilityTypesConstants.deleteReResponsibilityType.FAILURE:
		case reResponsibilityTypesConstants.restoreReResponsibilityType.FAILURE:
		case reResponsibilityTypesConstants.updateReResponsibilityType.FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}
		case reResponsibilityTypesConstants.createReResponsibilityType.SUCCESS:
			return {
				...state,
				isSending                 : false,
				responsibilityTypeChanged : action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null
			}
		case reResponsibilityTypesConstants.deleteReResponsibilityType.SUCCESS:
			return {
				...state,
				isSending                 : false,
				responsibilityTypeChanged : action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null
			}
		case reResponsibilityTypesConstants.restoreReResponsibilityType.SUCCESS:
			return {
				...state,
				isSending                 : false,
				responsibilityTypeChanged : action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : null
			}
		case reResponsibilityTypesConstants.updateReResponsibilityType.SUCCESS:
			return {
				...state,
				isSending                 : false,
				responsibilityTypeChanged : action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null
			}
		case reResponsibilityTypesConstants.SEND_RERESPONSIBILITYTYPES_CLEAR:
			return {
				...state,
				isSending                 : false,
				responsibilityTypeChanged : null,
				currentItem               : {},
				list                      : []
			}
		default:
			return state
	}
}