export const reResponsiblesExecutPositionConstants = {
  SEND_RERESPONSIBLESEXECUTPOSITION_REQUEST: 'SEND_RERESPONSIBLESEXECUTPOSITION_REQUEST',
  SEND_RERESPONSIBLESEXECUTPOSITION_SUCCESS: 'SEND_RERESPONSIBLESEXECUTPOSITION_SUCCESS',
  SEND_RERESPONSIBLESEXECUTPOSITION_FAILURE: 'SEND_RERESPONSIBLESEXECUTPOSITION_FAILURE',
  SEND_RERESPONSIBLESEXECUTPOSITION_CLEAR: 'SEND_RERESPONSIBLESEXECUTPOSITION_CLEAR',
  createResponsibleExecutPosition: {
    REQUEST: 'CREATE_RESPONSIBLEEXECUTPOSITION_REQUEST',
    FAILURE: 'CREATE_RESPONSIBLEEXECUTPOSITION_FAILURE',
    SUCCESS: 'CREATE_RESPONSIBLEEXECUTPOSITION_SUCCESS',
  },
  deleteResponsibleExecutPosition: {
    REQUEST: 'DELETE_RESPONSIBLEEXECUTPOSITION_REQUEST',
    FAILURE: 'DELETE_RESPONSIBLEEXECUTPOSITION_FAILURE',
    SUCCESS: 'DELETE_RESPONSIBLEEXECUTPOSITION_SUCCESS',
  },
  updateResponsibleExecutPosition: {
    REQUEST: 'UPDATE_RESPONSIBLEEXECUTPOSITION_REQUEST',
    FAILURE: 'UPDATE_RESPONSIBLEEXECUTPOSITION_FAILURE',
    SUCCESS: 'UPDATE_RESPONSIBLEEXECUTPOSITION_SUCCESS',
  },
  restoreResponsibleExecutPosition: {
    REQUEST: 'RESTORE_RESPONSIBLEEXECUTPOSITION_REQUEST',
    FAILURE: 'RESTORE_RESPONSIBLEEXECUTPOSITION_FAILURE',
    SUCCESS: 'RESTORE_RESPONSIBLEEXECUTPOSITION_SUCCESS',
  }
}